@import 'src/scss/variables';
.input-date-cell {
  min-width: 118px;
  input {
    position: relative;
    border-radius: 4px;
    height: 29px;
  }

  input:before {
    position: absolute;
    top: 27%;
    left: 10%;
    content: attr(data-date);
    display: inline-block;
  }

  input::-webkit-datetime-edit,
  input::-webkit-inner-spin-button,
  input::-webkit-clear-button {
    display: none;
  }
}

.icons-section {
  .margins-t4 {
    margin-top: -14px;
  }
}
.input-cell {
  .form-group {
    min-width: 70px;
    input.form-control {
      border-radius: 4px;
      height: 29px;
    }
  }
}
.drop-down-cell {
  select {
    height: 27px;
    border-radius: 4px;
    padding: 0 6px !important;
  }
  .form-group {
    min-width: 70px;
  }
}
