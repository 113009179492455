@import 'src/scss/variables';
.details-filter {
  h6 {
    color: #000000;
    font-size: 1.143rem;
    font-family: $headings-font-family;
  }

  .label {
    color: $label-gray;
    font-family: $font-family-base;
    font-size: 0.8571rem;
  }
}

.tab-content {
  background-color: transparent;
}

.error-style {
  margin-top: -9px;
  margin-bottom: 0px;
  font-size: 80%;
  color: #ff7272;
}

.nav-pills {
  .nav-link.active {
    background-color: lightgrey;
    color: black;
  }
  .nav-link {
    color: black;
  }
}

.a {
  color: black;
}

.documentBtn {
  background-color: '#DEE6FE';
  color: '#247CFA';
  text-overflow: 'ellipsis';
  overflow: 'hidden';
  white-space: 'no-wrap';
}

.button_section {
  position: relative;
  width: 95px;
  margin-right: 10px;
}
input[type='file']::-webkit-file-upload-button {
  height: 34px;
}
.hide_file {
  width: 95px;
  position: absolute;
  z-index: 1000;
  opacity: 0;
}

.invoicing-radio.form-check-inline .form-check-input {
  width: 26px !important;
  height: 26px !important;
  margin-right: 10px !important;
}

.invoicing-radio {
  margin-right: 3rem;
}
.mandatory-astrik-color {
  color: red;
}
.discard_button_brand {
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #344054;
  padding: 10px 14px;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid #d0d5dd;
}
.save_button_brand {
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 14px;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid #155eef;
}
.wotitleflex {
  display: flex;
  gap: 14px;
}
.field_wodetails {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: break-spaces;
}
.label_wodetails {
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: break-spaces;
}
.comments_btn_brand {
  border: 2px solid #d0d5dd;
  font-family: Inter;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  font-weight: 600;
  color: '#344054';
  padding: 8px 12px;
  line-height: 20px;
}
.success_wo_button {
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 14px;
  gap: 4px;
  border-radius: 8px;
  color: #29ab87;
  border: 2px solid #29ab87;
}

.invalid-entity-div {
  border: 1px solid #fecdca;
  background-color: #fef3f2;
  color: #f04438;
  padding: 0.75rem 1.5rem;
  gap: 1.5rem;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5px;
  display: flex;
  gap: 1rem;
  align-items: center;
  div {
    color: #004eeb;
    text-decoration: underline;
    cursor: pointer;
  }
}

.recycler_error_style {
  font-family: Inter;
  font-size: 90%;
  margin-top: -9px;
  color: #f04438;
}
