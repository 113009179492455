@import "src/scss/variables";
.ExecutionOverview{
    padding: 20px;
    .overview-met{
        background-color:$met-color;
    }

    .overview-Atrisk{
        background-color:$risk-color;
    }

    .overview-workInProgress{
        background-color:$workInProgress-color;
    }

    .overview-unallocated{
        background-color:$unallocated-color;
    }

    .overview-unplanned{
        background-color:$unplanned-color;
    }
    .overview-pending{
        background-color:$pending-color;
    }
}
