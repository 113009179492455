@import "src/scss/variables";

.Dashboard {
  margin: -20px !important;

  .dashboard-header {
    background: #ffffff;
    margin: 0;
    position: sticky;
    right: 0;
    top: $header-height;
    z-index: 9999;
    border-bottom: 1px solid #efefef;

    .col-9,
    .col-3 {
      padding: 10px 20px;
    }

    .timeline-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px 1.9rem 5px 5px;
      transition: all 0.1s ease-in-out;

      &:hover {
        background: #dce9ff;
        color: $primary;
        font-weight: bold;
        transition: all 0.1s ease-in-out;

        i {
          font-size: 1.2rem;
          transition: all 0.1s ease-in-out;
        }
      }
    }
  }

  .page-container {
    padding: 15px;
  }

  .timeline-sidebar {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  $_imp: unquote("!important");

  [role~="navigation"] {
    top: $header-height $_imp;
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 2px solid #f3f3f3;
  }

  .main-head {
    background-color: #ffffff;
    padding: 10px;
  }

  .target-col {
    min-width: 20%;
    max-width: 20%;
    margin-bottom: 2rem;
    padding-right: 10px;
    padding-left: 10px;
  }

  @media only screen and (max-width: 575.98px) {
    .target-col {
      max-width: 100%;
    }
  }

  .card.main-card {
    .card-body {
      padding: 0px !important;
    }
  }

  .dashboard-main-toggle {
    box-shadow: 0px 2px 4px #00000028;

    .btn-group-toggle {
      height: 50px;

      .browseNodeToggle {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn {
        background-color: #ffffff !important;
        border: none;
        color: #212529;
        width: 260px;

        .span {
          color: #000000;
          font-weight: bold;
        }
      }

      .btn.active {
        color: blue !important;
        font-weight: 600;
        background-color: #f6f8ff !important;
        width: 260px;

        .span {
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }

  .dashboard-inner-toggle {
    background-color: #f9f9f9;
    padding: 20px;
  }

  .overview-met {
    background-color: $met-color;
  }

  .overview-Atrisk {
    background-color: $risk-color;
  }

  .slick-prev {
    background-image: url(../../assets/img/slick-prev.svg);
    background-repeat: no-repeat;
    background-position: center;
    left: -16px;
  }

  .slick-next {
    background-image: url(../../assets/img/slick-prev.svg);
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    right: 0px;
  }

  .slick-prev:before,
  .slick-next::before {
    display: none;
  }
}
