@import './variables';

/*Main PAGE Card */
.card.main-card {
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  //box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  min-height: calc(var(--ch, 100vh) - (#{$header-height} + #{$breadcrumb-height}));
  .card-header {
    padding: 2rem;
    background: $light;
    h1 {
      font-size: $card-header-size;
    }
    .padding-aligned {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
  & > .card-body {
    padding: 2rem;
    font-size: 12px;
    //min-height: calc(100vh - 250px);
  }

  .card-empty-content {
    .row {
      display: flex;
      align-items: center;
      min-height: calc(100vh - 300px);
    }
  }
}
