@import "../../scss/variables";
.Blank{
  .card{
    margin-top: 10px;
  }
}
.documenthistory{
  .border-dashed{
    border-top: 1px dashed;
    border-color: #c0b8b8;
  }
  .close{
    font-weight: 100;
    font-size: 34px;
    padding: 1rem 0;
  }
  box-shadow: -10px 20px 45px #00000029;
  .documentheading{
    .title{
      font-size: 1.143rem;
      font-family: font-bold;
      .rejected{
        color: #FF7272;
      }
    }
    .view-docu{
      font-size: 1rem;
      color: #00C4FF;
      opacity: 1;
      cursor: pointer;

    }
  }
  .author{
    label{
      font-size: 1rem;
    }
    p{
      font-size: 0.7143rem;
      color: #7F7F7F;
    }
  }
}

.documenthistory-comment-file-textarea{
  textarea{
    border: none;
    resize: none;
    font-size: 1.143rem;
    color: #B6B6B6;
  }
  .file-attachments{
    font-size: 1rem;
    color: #000000;
    opacity: 1;
  }
  .attach-file-btn, .attach-file-btn:hover, .attach-file-btn:focus{
    background-color: transparent !important;
    color: $dark!important;
    border: none!important;
    font-size: 1rem;
    box-shadow: none !important;
  }
  .dropdown-toggle::after{
    display: none;
  }
  .jumbo{

    background-color: #F5F5F5;
  }
  // .dropdown-icon::before{
  //   width: 20px;
  //   height: 20px;
  //   background-color: red;
  //   position: absolute;
  //   left: -8px;
  //   top: 30px;
  //   transform: rotate(45deg)
  // }
  .dropdown-icon::before {
    content: " ";
    width: 12px;
    height: 12px;
    background-color: $light;
    position: absolute;
    left: 28px;
    top: -7px;
    transform: rotate(45deg);
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
  }
  .dropdown-icon{
    box-shadow: -4px 3px 6px #00000029;
    border: 1px solid #dfd3d3;
  }
  .dropdown-item{
    padding: 10px 10px 5px 10px;
    color: $dark;
    font-size: 1rem;
  }
  .dropdown-item:hover{
    background-color: #F1F1F1;
  }
  .first-label{
    color: #7F7F7F;
  }
}


.float-label-section{
  .float-icon{
    position: fixed;
    top: 256px;
    right: 0;
    background-color: #8490A5;
    padding: 12px 20px 12px 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 35px 0px 0px 35px;
    z-index: 1000;
    width: 45px;
    transition: .2s ease-out;
    cursor: pointer;
    opacity: .6;
  }
  .float-icon:hover{
    width: 70px;
    opacity: 1;
  }
  .hide-icon{right:-50px;}
}
