.QuantityOverview{
    padding: 20px;
    &>.row {
        padding-left: 5px;
        padding-right: 5px;
        .col{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
