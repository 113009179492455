@import "src/scss/variables";

.TargetStepCard{
  min-height: 191px;
  button{
    font-size: 1.5rem;
    padding: 0;
  }
  p{
    font-size: 1.286rem;
    color: $dark;
  }
  .card-body{
    border-radius: 2.5px;
    border: solid 0.5px #d7d7d7;
    background-color: #ffffff;
  }
}
