@import "src/scss/variables";

.DashboardMaterialFilter {
    overflow: hidden;

    .btn-group-toggle {
        display: initial;
        .btn {
            white-space: pre;
            margin: 0px 10px;
            border: 1px solid #DDDDDD;
            border-radius: 5px !important;
            background: transparent;
            margin-top: 10px;
            padding: 3px 10px;

            &.active {
                background-color: #005DFF12 !important;
                border: 1px solid transparent;
                font-family: font-medium;
                color: $primary;
            }
        }
    }

}
