.marketPlaceCardStyle {
  height: 70px;
  margin-top: 6px;
  padding: 10px 16px 0 16px;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
  background-color: #fff;
}

.accordianBody {
  margin-top: -20px;
  height: 300px;
  overflow-y: scroll;
}

.generateWOButton {
  margin-top: -40px;
  float: right;
}

.MpCreditsQty {
  margin: 8px 8px 9px 0;
  font-family: SFProDisplay;
  font-size: 16px;
  text-align: left;
  color: #6c757d;
}
.-MT-Qty {
  font-family: SFProDisplay;
  font-size: 16px;
  text-align: left;
  color: #000;
}
.-MT-Qty .text-style-1 {
  font-weight: bold;
}

.mpAvailableCreditsLabel {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #6c757d;
}

.mpCreditsLabel {
  margin-top: 13px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #252733;
}

.mpCreditsLabel .text-style-1 {
  color: #078246;
}
.mpCreditsLabel .text-style-2 {
  font-weight: normal;
  color: #000;
}

.MpCreditsQty .text-style-1 {
  font-weight: bold;
}

.CreditsLabel {
  height: 14px;
  font-family: SFProDisplay;
  font-size: 16px;
  text-align: left;
  color: #6c757d;
}

.Vector {
  width: 7px;
  height: 14px;
  transform: rotate(-180deg);
  object-fit: contain;
}

.Rectangle-7355 {
  width: 15px;
  height: 56px;
  flex-grow: 0;
  padding: 21px;
  transform: rotate(-180deg);
  background-color: rgba(165, 166, 246, 0.41);
}

.Feather-Icons-chevron-left {
  left: 100.01%;
  right: -100.01%;
  margin-left: 5px;
  top: 100%;
  bottom: -100%;
  width: 12px;
  height: 12px;
  background: #005dff;
  border-radius: 4px;
}

.-MT {
  width: 48px;
  height: 20px;
  flex-grow: 0;
  margin: 8px 0 0;
  font-family: SFProDisplay;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.-MT .text-style-1 {
  font-weight: normal;
}

.MonthName {
  width: auto;
  height: auto;
  flex-grow: 0;
  margin: 0 23px 8px 0;
  font-family: SFProDisplay;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6c757d;
}

.Vector-131 {
  width: 1px;
  height: 40px;
  flex-grow: 0;
  margin: 10px 10.5px 6px 4.9px;
  background-color: grey;
}
.ScrollBar {
  padding: 0 40px;
  margin: 5px 10px;
  border: none;
  background-color: #f8f8f8;
}

.marketPlaceCardHeader {
  display: flex;
  background-color: #f0f0f0;
}
.Rectangle-7353 {
  width: 100%;
  height: 56px;
  flex-grow: 0;
  margin: 0 0 7px 8px;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #f8f8f8;
}
.marketPlaceAvailableCardStyle {
  width: 100%;
  height: 70px;
  flex-grow: 0;
  margin: 6px 0 0 6px;
  padding: 3px 3px 0 2px;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
  background-color: #fff;
}
.marketPlaceCreditsText {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.marketPlaceCardSection {
  display: flex;
}

.cardLabel {
  font-size: 16px;
  font-weight: normal;
  color: #6c757d;
  margin-bottom: 0px;
}

.cardQuantityValue {
  font-size: 18px;
  font-weight: bold;
  width: 70px;
}

.quantityText {
  font-size: 12px;
  font-weight: 200;
  margin-left: -2px;
}

.cardVerticalLine {
  width: 1px;
  height: 15px;
  margin: 4px 18px 17px 15px;
  background-color: #000;
}

.supplyVendorToggle {
  height: 70px;
  // padding: 9px 12px;
  gap: 10px;
  background: #fff;
}

.Card {
  background-color: #f0f0f6;
}

.horizontal-line-month-wise {
  border: 0.1em solid #c9c6c6;
  border-radius: 5%;
}
