/**
 * Set Custom Fonts family and standard font sizes
 * should be written here.
 */

@font-face {
  font-family: 'font-regular';
  src: url(../assets/fonts/SF-Pro-Display-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'font-medium';
  src: url(../assets/fonts/SF-Pro-Display-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'font-semibold';
  src: url(../assets/fonts/SF-Pro-Display-Semibold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'font-light';
  src: url(../assets/fonts/SF-Pro-Display-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'font-bold';
  src: url(../assets/fonts/SF-Pro-Display-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'font-black';
  src: url(../assets/fonts/SF-Pro-Display-Black.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

/** ==================================
--------------Font family--------------
======================================*/
.font-light {
  font-family: font-light;
}
.font-regular {
  font-family: font-regular;
}
.font-medium {
  font-family: font-medium;
}
.font-semibold {
  font-family: font-semibold;
}
.font-bold {
  font-family: font-bold;
}

/** ==================================
--------------Text Sizes--------------
======================================*/
.text-xxsmall {
  font-size: 0.5714rem;
}
.text-xsmall {
  font-size: 0.7143rem;
}
.text-small {
  font-size: 0.8571rem;
}
.text-regular {
  font-size: 1rem;
}
.text-medium {
  font-size: 1.143rem;
}
.text-large {
  font-size: 1.286rem;
}
.text-xlarge {
  font-size: 1.429rem;
}
.text-hlarge {
  font-size: 1.714rem;
}

/**
PX to REM Converter scale as base 14px
8px = 0.5714rem
9px = 0.6429rem
10px = 0.7143rem
11px = 0.7857rem
12px = 0.8571rem
13px = 0.9286rem
14px = 1rem (base)
15px = 1.071rem
16px = 1.143rem
18px = 1.286rem
20px = 1.429rem
22px = 1.571rem
24px = 1.714rem
26px = 1.857rem
28px = 2rem
30px = 2.143rem
32px = 2.286rem
34px = 2.429rem
36px = 2.571rem
38px = 2.714rem
40px = 2.857rem
 */
