@import '../../../../scss/variables';

.delivard-image-details {
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  .modal-body {
    height: 100% !important;
    min-height: 500px !important;
    overflow: hidden !important;
  }
  .modal-lg {
    max-width: 1320px;
  }
  .modal-content {
    background-color: #eff0f5;
  }
  p {
    font-size: 1rem;
  }
  .sub-list {
    label {
      color: $dark;
      font-size: 1rem;
    }
  }
  .document-details-height {
    height: 175px;
    overflow: auto;
  }
  .dispute {
    position: absolute;
    top: 130px;
    right: 0;
  }
  .img-height {
    height: 475px;
  }
  .tab-ver-3 .tab-content {
    min-height: 400px !important;
    height: 100% !important;
  }
  .resolution {
    .max-w {
      max-width: 180px;
      border-radius: 5px;
      background-color: $page-bg-color;
      text-align: center;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .modal-body {
    height: 575px;
  }
  .border-radius-5px {
    border-radius: 5px;
  }
}
.edit-label {
  color: #90979f !important;
  font-size: 1rem !important;
}

.tab-ver-3 {
  .card-header-tabs {
    a:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}
.invoiceLoadingText {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.ewayBillInvoiceButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delRowHeading {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
}
.delRowText {
  margin-bottom: 15px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.weighBridgeRowHeading {
  margin-bottom: 2px;
  color: #7f7f7f;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.3px;
}
.cursorStyle {
  cursor: pointer;
}
