// Variable overrides
// change the theme
/**
 * variable for theming write here and use in you app
 */

/** -----------------------------------------
=========== BOOTSTRAP VARIABLES =========
-------------------------------------------**/

$primary: #005dff !default; //bright-blue
$secondary: #f0f0f0 !default; //sky-blue
$success: #00b9ba !default; //turquoise
$info: #59e1ca !default; //seafoam-blue
$warning: #ffb757 !default; //pale-orange
$danger: #ff7272 !default; //salmon
$light: #ffffff !default;
$dark: #000000 !default; //blue-grey
$gray: #f6f8ff !default; //gray
$dark-gray: #65677b !default; //dark gray
$label-gray: #7f7f7f !default; //label-gray
$table-first-col: #f5f5f5 !default; //table-first-col
$font-family-base: 'font-regular' !default;
$font-size-base: 1rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$card-header-size: $font-size-base * 1.714 !default;

$headings-margin-bottom: 0 !default;
$headings-font-family: 'font-bold' !default;
$headings-font-weight: null !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

// Breadcrumbs
$breadcrumb-font-size: 1rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: $light !default;
$breadcrumb-divider-color: $secondary !default;
$breadcrumb-active-color: $dark !default;
$breadcrumb-divider: quote('>') !default;

//INPUT
$input-border-radius: 4px !default;
$input-focus-border-color: none !default;
$input-height: 40px !default;
$input-font-size: 1rem !default;
$input-border-width: 1px !default;
$input-border-color: #dfdede !default;

//FormLabel
$form-label-color: #7f7f7f;
//Link
// $link-color:                  $secondary !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;

//Button
$btn-font-size: 1rem !default;

//ToggleButton

//Spinner
$spinner-border-width: 2px !default;

//ProgressBar
$progress-border-radius: 0 !default;
$progress-height: 20px !default;

//Table
$table-head-bg: #f5f5f5 !default;
$table-head-color: $dark !default;
$table-border-color: #d2d2d2 !default;
$table-head-blue-light: #f2f5ff !default;
$table-head-blue-medium: #d5e1ff !default;
$table-head-sort-default: #afafaf !default;

/** -----------------------------------------
=========== OTHER CUSTOM VARIABLES =========
-------------------------------------------**/
$page-bg-color: #eff0f5;
// ANIMATIONS
$animation-duration: 0.5s;

$layout-content-padding-left: 20px;
$layout-content-padding-right: 20px;
$layout-content-padding-top: 20px;
$layout-content-padding-bottom: 20px;
$layout-content-padding: 14px;

$header-height: 51px;
$header-color: #ffffff;

$breadcrumb-height: 42px;
$header-color: #ffffff;

$sidebar-width: 218px;
$sidebar-mini-width: 70px;
$sidebar-bg: #ffffff;

// buttons
$btn-border-light: #dfdede;
$btn-light: #f5f5f5;

// document status
$doc-stat-review: #7297ff;
$doc-stat-reject: #ff7272;
$doc-stat-no: #ffcf8f;

//Target status
$met-color: #7ae1ca;
$risk-color: #e66d71;
$workInProgress-color: #f8cb1c;
$unallocated-color: #7297fe;
$pending-color: #ed9714;
$unplanned-color: #e9ecef;
