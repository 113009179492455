/**
 * Style for element table, pagination, td, tr, thead etc.
 * should be written/override here
 */
@import 'variables';
table {
  .thead-light {
    th {
      font-family: font-regular;
      font-weight: normal;
    }
  }
  th,
  td {
    font-size: 1rem;
    white-space: nowrap;
    vertical-align: middle !important;
  }
  .highlight-row {
    border: solid 3px $danger;
  }
}
.custom-table {
  .thead-light {
    th {
      font-family: $font-family-base;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #dee2e6;
      border-left: 1px solid #e0e0e0;
    }
  }
  td {
    border-right: 1px solid #e0e0e0;
    color: $dark;
    font-size: 1rem;
    font-family: $font-family-base;
    border-bottom: 1px solid #dee2e6;
  }
  td:first-child {
    font-size: 1.143rem;
    font-family: $headings-font-family;
    border-left: 1px solid #e0e0e0;
  }
  td:second-child {
    font-size: 1.143rem;
  }
  td:nth-child(2) {
    font-size: 1.143rem;
    color: $dark;
  }
  td:nth-child(7) {
    font-family: $headings-font-family;
    color: $dark;
  }
}
.column-sort {
  user-select: none;
  svg {
    color: $table-head-sort-default !important;
  }
  .active {
    color: $dark !important;
  }
}
.top-header-table {
  border: none !important;
}
.table-bordered {
  border-left: none;
  font-size: 12px;
  border-right: none;
  border-top: none;
}

.table {
  .form-control {
    font-size: 0.8571rem;
  }
  select[class='form-control'] {
    border: none !important;
  }
}
.left-col-bg {
  background-color: #ece7e7;
}

.border-radius-0 {
  border-radius: 0;
}

// table second type
.custom-table-two {
  td:first-child {
    font-size: 1rem !important;
    font-family: $font-family-base;
  }
  td:nth-child(2) {
    font-size: 1rem;
  }
  td:nth-child(7) {
    font-family: $font-family-base;
  }
  td:first-child {
    background-color: $table-first-col;
  }
  .thead-light th {
    background-color: $table-first-col;
    color: $dark;
    font-weight: 500;
  }
}

// table third type
.custom-table-three {
  .delivery-table-heading {
    font-family: $font-family-base !important;
  }
  .delivery-table {
    td {
      font-family: $font-family-base !important;
    }
    .hide-btn {
      display: none;
    }
  }

  td:first-child {
    font-size: 1rem !important;
    font-family: $font-family-base;
  }
  td:nth-child(5) {
    font-size: 1rem;
    font-family: $headings-font-family;
  }
  td:nth-child(2) {
    font-size: 1rem;
  }
  th:nth-child(5) {
    font-family: $headings-font-family;
    font-size: 1.143rem;
  }
  th:nth-child(6) {
    font-family: $headings-font-family;
    font-size: 1.143rem;
  }
  td:nth-child(7) {
    font-family: $font-family-base;
  }
  .thead-light th {
    background-color: $table-first-col;
    color: $dark;
    font-weight: 500;
  }
}

.delivery-table {
  .cell-stat-review {
    background: rgba(red($doc-stat-review), green($doc-stat-review), blue($doc-stat-review), 0.1);
    .fa-circle {
      color: $doc-stat-review;
    }
  }
  .cell-stat-reject {
    background: rgba(red($doc-stat-reject), green($doc-stat-reject), blue($doc-stat-reject), 0.1);
    .fa-circle {
      color: $doc-stat-reject;
    }
  }
  .cell-stat-no-doc {
    background: rgba(red($doc-stat-no), green($doc-stat-no), blue($doc-stat-no), 0.1);
    .fa-circle {
      color: $doc-stat-no;
    }
  }
  .cell-stat-approved {
    background: rgba(red($info), green($info), blue($info), 0.1);
    .fa-circle {
      color: $info;
    }
  }
  .document-stat-heading {
    background: $table-head-blue-medium;
    padding: 0px;
  }
  .delivery-table-heading {
    background: $table-head-blue-light;
  }
}

.table-input-cell {
  padding: 0 !important;
}

.rcyl-table1 {
  $cell-padding: 0.55rem;
  thead {
    th {
      padding: $cell-padding;
      vertical-align: middle;
      // &:last-child{
      //   position: sticky;
      //   right: 0;
      // }
    }
  }
  tbody {
    td {
      padding: $cell-padding;
      vertical-align: middle;
      background: white;
    }
  }
  .fixed-col-right {
    position: sticky;
    right: 0;
  }
  .fixed-col-left {
    position: sticky;
    left: 0;
  }
  // th
  // td {
  // &:last-child {
  //   position: sticky;
  //   right: 0;
  //   box-shadow: -2px 0px 0px 0px #e1e1e1;
  // }
  /*&:first-child{
      position: sticky;
      left: 0px;
      box-shadow: 0px 0px 0px -2px #e1e1e1;
    }
    &:nth-child(2){
      position: sticky;
      left: 39.8px;
      box-shadow: 0px 0px 0px -2px #e1e1e1;
    }*/
  // }s
}
