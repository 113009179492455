@import "../../../../scss/variables";
.DocumentHistoryItem{
  
}


.documenthistory-comment{
  .jumbo{
    border:1px solid #DBDBDB;
    background-color: #F5F5F5;
  }
  p{font-size: 1rem;
  span{
    color: #212529;
    pointer-events: none;
    padding: 2px 6px;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: #dcdcdc;
  }
  
  }
}

.documenthistory-comment-box{
  .custom-control-label{
    font-size: 1rem;
    color: #929292;
    font-family: font-bold;
  }
  p{
    font-size: 1.143rem;
  }
  .file-attachments{
    font-size: 1rem;
    color: #000000;
    opacity: 1;
  }
  .jumbo{

    background-color: #F5F5F5;
  }
  .attachments{cursor: pointer;}
}

.documenthistory-comment-file-textarea{
  textarea{
    border: none;
    resize: none;
    font-size: 1.143rem;
    color: #B6B6B6;
  }
  .file-attachments{
    font-size: 1rem;
    color: #000000;
    opacity: 1;
  }
  .attach-file-btn, .attach-file-btn:hover, .attach-file-btn:focus{
    background-color: transparent !important;
    color: $dark!important;
    border: none!important;
    font-size: 1rem;
    box-shadow: none !important;
  }
  .dropdown-toggle::after{
    display: none;
  }
  .jumbo{

    background-color: #F5F5F5;
  }
  // .dropdown-icon::before{
  //   width: 20px;
  //   height: 20px;
  //   background-color: red;
  //   position: absolute;
  //   left: -8px;
  //   top: 30px;
  //   transform: rotate(45deg)
  // }
  .dropdown-icon::before {
    content: " ";
    width: 12px;
    height: 12px;
    background-color: $light;
    position: absolute;
    left: 28px;
    top: -7px;
    transform: rotate(45deg);
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
  }
  .dropdown-menu {
    height: 300px;
    overflow-y: scroll;
  }
  .dropdown-icon{
    box-shadow: -4px 3px 6px #00000029;
    border: 1px solid #dfd3d3;
  }
  .dropdown-item{
    padding: 5px 5px 5px 20px;
    color: $dark;
    font-size: 0.9rem;
  }
  .dropdown-item:hover{
    background-color: #F1F1F1;
  }
  .first-label{
    color: #7F7F7F;
  }
}
