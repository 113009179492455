.cardHead {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #344054;
  margin-bottom: 10px;
  font-family: Inter;
}

.multiUploadCard {
  width: Fill (236px) px;
  height: Hug (40px) px;
  padding: 5px;
  gap: 6px;
  border-radius: 3px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.uploadCardHead {
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 20px;
color: #344054;
}


.just-space-betw {
  justify-content: space-between;
}

.align-i-center{
  align-items: center;
}
.uploadaccepttext{
  color: #475647;
  font-family: Inter;
  white-space: no-wrap;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin-top: -0.8rem;
  margin-bottom: 10px;
  }
  

.upload-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

input[type='file'] {
  display: none; /* Hide the default file input */
}
.uploadaccepttext{
  color: #475647;
  font-family: Inter;
  white-space: break-spaces;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin-top: -0.8rem;
  margin-bottom: 10px;
  }
  
.accordion_toggle_header{
  height: 2.5rem;
}
.accordion_header_text{
font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
white-space: break-spaces;

}

.linkdocumentmulti{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  text-decoration-line: underline;
  text-decoration-style: solid;
  white-space: break-spaces;
  
  }
  .docums_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
     overflow: auto ; 
  }
  .documents_upload_div{
    height: 300px;
     overflow: auto; 
    position: relative;
  }
  