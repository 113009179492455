/**
 * Style for all form elements like InputGroup, input, select, radio, checkbox etc.
 * should be written/override here
 */

@import 'variables';
/** -----------------------------------------
================= TEXTBOX FIELD ==============
-------------------------------------------**/
.Select.is-open {
  position: relative;
  z-index: 9999;
}
.form-control,
.select__control {
  font-size: $input-font-size;
  color: $dark !important;
  font-family: $font-family-base;
  //border-bottom:1px solid #e4e7ea !important;
  //border:none;
  &.float-label {
    border-bottom: 1px solid #e4e7ea !important;
    border: none;
    &:disabled {
      background-color: transparent;
    }
  }
}
.select__menu {
  z-index: 99999 !important;
}

//form-label
.form-label {
  color: $form-label-color;
}

select.form-control:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus,
[type='text'].form-control:focus,
[type='password'].form-control:focus,
[type='email'].form-control:focus,
[type='tel'].form-control:focus,
.form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0px 0 #fff;
  outline: none;
}

/** -----------------------------------------
================= TEXTAREA FIELD ==============
-------------------------------------------**/

/** -----------------------------------------
================= RADIO BUTTON ==============
-------------------------------------------**/
// type-one radio button
.radio-custom {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: transparent;
    border-color: transparent;
  }
  .custom-radio {
    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url(../assets/img/radio-checked.png);
      background-size: 100%;
    }
  }
}
// outline removed for radio and check button
.custom-control-input:focus ~ .custom-control-label::before {
  outline: 0;
  box-shadow: none;
}

//type 2 radio button
.select-radio-btn {
  .custom-radio {
    .custom-control-label::before {
      border-radius: 0;
    }
  }
  .custom-radio {
    .custom-control-input:checked ~ {
      .custom-control-label::after {
        left: -16px;
        top: 4px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  // .custom-control-label::before {
  //   border: none;
  // }
}

/** -----------------------------------------
================ CHECKBOX BUTTON ============
-------------------------------------------**/

/** -----------------------------------------
================ SELECT OPTIONS =============
-------------------------------------------**/
// select {
/*  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
  border-radius: 0*/
// }
select {
  &.form-control {
    border-radius: 0;
  }
}

//react-select library style
.react-select {
  .select__control {
    border-radius: $input-border-radius;
    min-height: $input-height;
    border-color: $input-border-color;
    &.select__control--is-focused,
    &.select__control--is-focused:hover {
      border-color: $input-border-color;
      border-width: $input-border-width;
      box-shadow: none;
    }
  }
  &.is-invalid .select__control {
    border-color: #ff7272;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff7272' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff7272' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.react-select-no-icon {
  .select__control {
    border-radius: $input-border-radius;
    min-height: $input-height;
    border-color: $input-border-color;
    &.select__control--is-focused,
    &.select__control--is-focused:hover {
      border-color: $input-border-color;
      border-width: $input-border-width;
      box-shadow: none;
    }
  }
  &.is-invalid .select__control {
    border-color: #ff7272;
  }
}
/** -----------------------------------------
================== DROPDOWNS ================
-------------------------------------------**/

/** -----------------------------------------
=============== DATE/TIME PICKED ============
-------------------------------------------**/
.react-bootstrap-daterangepicker-container {
  width: 100% !important;
  padding: 0;
}
.daterangepicker {
  width: max-content;
}
.date-picker-bg-img-icon {
  .datepicker-field {
    background-image: url(../assets/img/datepicker-icon.svg);
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 1.1em;
    padding: 0 0 0 10px;
  }
}

.create-date-picker-bg-img-icon {
  .datepicker-field {
    background-image: url(../assets/img/create-date-icon.svg);
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 1.1em;
    word-spacing: 0.1em;
    letter-spacing: 1px;
    padding: 0 0 0 10px;
  }
}

.input-date-no-picker {
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  &[value='']::-webkit-datetime-edit {
    color: transparent;
  }
  &:focus::-webkit-datetime-edit {
    color: black !important;
  }
}
.left-date {
  .datepicker-field {
    background-position: 6%;
    padding: 0 0 0 30px;
  }
}
/** -----------------------------------------
=============== input type file ============
-------------------------------------------**/
.change-pdf {
  input[type='file'] {
    cursor: pointer;
    width: 100px;
    height: 34px;
    overflow: hidden;
  }
  input[type='file']:before {
    width: 100px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    content: 'Update';
    display: inline-block;
    background: white;
    border: 1px solid #000;
    padding: 0 10px;
    text-align: center;
    font-family: $font-family-base;
  }
  input[type='file']::-webkit-file-upload-button {
    visibility: hidden;
  }
}
// group-button
.group-all {
  .btn {
    min-width: auto;
  }
  .btn-primary,
  .btn-primary:active {
    background-color: $btn-border-light;
    color: $dark;
    font-size: 1rem;
    border-radius: 1.5px;
    border: solid 0.5px $btn-border-light;
    // padding: 13px 34px 12px 34px;
    // width: 155px;
  }
  .active.btn-primary {
    background-color: $light !important;
    color: $dark !important;
    border-radius: 1.5px !important;
    border: solid 0.5px $btn-border-light !important;
  }
}

// date picker-2
// .form-control-one {
//letter-spacing: 2.5px;
//border-radius: 1.5px;
//border: solid 0.5px $btn-border-light;
//background-color: $light;
//padding: 25px 25px 25px 25px !important;
//font-size: 1rem;
//font-family: $font-family-base;
// }

// multiple select
.rbt-input-multi,
.rbt-input-multi:hover {
  padding: 8px 10px 10px 10px;
  height: $input-height;
  font-size: $input-font-size;
  border-radius: 1.5px;
  border: solid 0.5px $btn-border-light;
  background-color: $light;
  font-family: $font-family-base;
}
.rbt-token,
.rbt-token:hover {
  background-color: $primary;
  color: $light;
  font-size: 1rem;
  padding: 6px 20px 7px 10px;
}
