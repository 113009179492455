/**
 * Style for all Tabs and segments should be written here
 */
.nav-tabs {
  margin-bottom: 0;

  .nav-item.nav-link {
    padding: 10px 28px;
    color: $dark;
    font-size: 1rem;
    opacity: 0.5;
  }

  .nav-item.nav-link.active {
    padding: 10px 28px;
    color: $dark;
    background-color: $table-head-bg;
    opacity: 1;
    border-color: #fff #fff #f5f5f5;
  }
}
.tab-content {
  background-color: $table-head-bg;
}
.tabs-bg {
  background-color: $page-bg-color;
}
.tab-style {
  .nav-tabs {
    .nav-link.active {
      border: 1px solid transparent;
    }
  }
  .tab-content {
    border: none !important;
  }
  .nav-tabs {
    .nav-link:hover,
    .nav-tabs .nav-link:focus {
      border: 1px solid transparent;
    }
  }
  .nav-link {
    padding: 10px 15px 10px 15px;
  }
}

.tab-two {
  .nav-tabs {
    background-color: $page-bg-color;
  }
}
.tab-ver-3 {
  .tab-content {
    background-color: $light;
    height: 500px;
  }
  .tab-v3 {
    .nav-link.active {
      background-color: $light !important;
      border: none;
    }
  }
}
