.Toastify__toast {
  border-radius: 5px;
  min-height: 80px;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1;
  padding: 0 15px;
}
.Toastify__close-button{
  float: right;
}
