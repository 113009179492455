// Here you can add other styles
@import './card';
@import './toast';
@import './tags';
@import './modal';
@import './form-elements';
@import './tabs';
@import './list';
@import './table.scss';
@import './button';
@import './scrolling-bar';
@import './fields';

html {
  font-size: 14px;
  scroll-behavior: smooth;
}
.pointer {
  cursor: pointer;
}
//remove outline from element
.btn:not(.disabled).focus {
  outline: 0;
  box-shadow: none !important;
}
.page-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.bg-green {
  background: #28a745;
}
.text-green {
  color: #28a745;
}

body {
  background-color: $page-bg-color;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

//section heading
h6 {
  margin-bottom: 20px;
}

.sub-heading {
  font-size: 1.143rem;
  margin-bottom: 10px;
  font-family: font-bold;
}

// override animation duration for animate.css
.animated {
  -webkit-animation-duration: $animation-duration;
  animation-duration: $animation-duration;
}

//show label
.static-label {
  div {
    color: $form-label-color;
  }
  p {
    color: $dark;
    margin-top: 4px;
    font-size: 1rem;
  }
}

.table-light-bg {
  background: #f1f6ff;
}
//no internet
.internet-error {
  position: fixed;
  left: 50%;
  min-width: 121px;
  justify-content: center;
  z-index: 9999;
  align-items: center;
  display: flex;
  font-family: font-medium;
  font-size: 0.8rem;
  color: #fff;
  animation-duration: 0.5s;
  padding: 2px 15px;
  border-radius: 50px;
  top: 6px;
}
.heading-bg {
  background-color: $table-head-bg;
}

.page-bg {
  background-color: $page-bg-color;
}

.app-loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-loading {
  height: calc(90vh - #{$header-height});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.padding-2rem {
  padding: 2rem;
}

.filter-div {
  .react-bootstrap-daterangepicker-container {
    width: 100%;
  }

  .label {
    color: $label-gray;
    font-family: $font-family-base;
    font-size: 1rem;
  }
  .filters {
    color: $label-gray;
    font-family: $font-family-base;
    font-size: 1rem;
  }
}

.top-section {
  .label {
    color: #7f7f7f;
    font-family: 'font-regular';
    font-size: 0.8571rem;
  }
  h6 {
    color: $dark;
    font-size: 1.143rem;
    font-family: $headings-font-family;
  }
  p {
    font-size: 1rem;
    color: $dark;
    font-family: $font-family-base;
  }
  .delivered {
    color: $dark;
    font-size: 1.471rem;
  }
}
.spinner-border-sm {
  border-width: 0.11em;
}
.heading-bg {
  background-color: $table-head-bg;
}

.hidden {
  display: none !important;
}

.file-name-text {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.z-100 {
  z-index: 100;
}
.sticky-page-header {
  left: 0;
  transition: all 0.25s;
}
.sidebar-open {
  .sticky-page-header {
    left: $sidebar-width;
    transition: all 0.25s;
  }
}

.popover {
  max-width: 400px;
}
