@import '../../../scss/variables';
.login-card {
  // width: 438px;
  height: auto;
  border-radius: 2.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: $light;

  .card-header {
    padding: 2rem;
    background: $light;
  }
  .card-body {
    padding: 2rem;
    background: $light;
  }
}
