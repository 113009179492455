@import '../../../../scss/variables';

.DocumentCard {
  height: 100%;
  width: 100%;
  border-radius: 1rem 1rem 0 0;

  .white-bg {
    background-color: white;
  }
  .grey-bg {
    background-color: #f5f5f5 !important;
  }
  .doc-select {
    padding: '0 20px 0 10px';
    margin-left: '-10px';
  }

  .image-view {
    min-height: 12.5rem;
    object-fit: cover;
    object-position: center;
    width: auto;
  }

  select {
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
    border-radius: 0;
    color: $primary !important;
  }

  .btn-outline-secondary {
    border-color: lightgrey;
    color: black;
  }

  .approved {
    color: #66a303;
  }

  .dispute {
    color: #f80c0c;
  }

  .approved-rejected {
    position: absolute;
    z-index: 99;
    top: 7px;
    left: 7px;

    span {
      text-transform: uppercase;
      font-size: 10px !important;
      padding: 5px 7px;
      font-family: font-light !important;
      letter-spacing: 1px;
    }

    .approved {
      border: 1px solid #66a303;

      h6 {
        color: #66a303;
      }
    }

    .rejected {
      border: 1px solid #f80c0c;
    }

    h6 {
      color: #f80c0c;
    }
  }

  .upload-btn {
    position: absolute;
    bottom: 1px;
    right: 10px;
    cursor: pointer;
  }

  .card {
    border: 0;
    height: 100%;

    .card-header {
      padding: 4px 4px 10px 4px;
      border: 0;

      select {
        padding: 0;
        border-bottom: 0 !important;
        font-size: 12px;
        height: 20px;

        &:invalid {
          color: green;
        }

        &[disabled] {
          background-color: transparent;
          background-image: none;
        }
      }
    }

    .card-body {
      height: 200px;
      width: 100%;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      padding: 0;
      font-size: 12px;

      .no-doc-uploaded {
        display: flex;
        padding: 1.25rem;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        text-align: center;
      }

      .doc-uploaded {
        height: 100%;
        width: 100%;

        img {
          border-radius: 3px;
          height: 100%;
          width: 100%;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(40, 44, 63, 0.17);
          content: '';
          background-blend-mode: overlay;
        }
      }

      &.active {
        border-color: $primary;
        outline: 0;
        border-radius: 3px;
        box-shadow: 0 0 0 2px $primary;
      }

      &.empty {
        border: 1px dashed #979797;
      }
    }

    .card-footer {
      padding: 0px;
      border: 0;
      background-color: transparent;
      text-align: right;

      .btn-toolbar {
        justify-content: flex-end;

        button {
          margin-left: 0;
          min-width: auto;
        }
      }
    }
  }
}
