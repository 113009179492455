.Accordian-Header {
  width: auto;
  height: 50px;
  margin: 0 0 11px;
  padding: 12px 18px 8px 15px;
  background-color: #e4ebf8;
}

.ItemWisecard {
  margin-bottom: 40px;
  max-height: 500px;
  scroll-behavior: auto;
  overflow-y: scroll;
}

.marketPlaceDemand {
  height: 50px;
  flex-grow: 0;
  padding: 7px 22px 15px 38px;
  background-color: #e4ebf8;
}

.tableWidth {
  overflow: auto;
  width: auto;
  max-height: 500px;

  overflow-y: auto;
}

.marketPlaceVendorSupply {
  width: auto;
  height: 50px;
  padding: 0px 25px 3px 38px;
  margin-bottom: 10px;
  background-color: #dff2e9;
}
.innerAccordian {
  width: auto;
  height: auto;
  flex-grow: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.marketPlaceVendorSupplyText {
  width: 190px;
  height: 19px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000;
}
.Accordian {
  width: 100%;
  height: auto;
  margin: 31px 0 0 4px;
  background-color: rgba(255, 255, 255, 0.41);
}

.Card {
  background-color: #f0f0f6;
}
.AccordianMain {
  width: auto;
  height: 90px;
  padding: 10px 9px 11px 3px;
  background-color: #fff;
}

.subHeading {
  width: auto;
  // height: 22px;
  margin: 11px 2px 0 14px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #252733;
}
.AccordianArrow {
  float: right;
}
.brandDemandHeader {
  padding: 12px 20px;
  background-color: #e4ebf8;
}
.vendorSupplyHeader {
  padding: 12px 20px;
  background-color: #dff2e9;
}
.brandDemandHeaderText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #101828;
}

.verticalLine {
  width: 1px;
  flex-grow: 0;
  margin: 2px 9.5px 3px;
  background-color: rgba(165, 166, 246, 0.41);
}
.marketPlaceOuterLines {
  padding: 10px 5px 11px 15px;
  border-radius: 4px;
  border: solid 1px #5d5e74;
  background-color: #fafafa;
  flex-grow: 1;
}
.tagYellow {
  align-items: center;
  padding: 2px 8px;
  border-radius: 50px;
  border: solid 1px #f5efb9;
  background-color: #fefadc;
}
.tagGreen {
  height: 20px;
  align-items: center;
  padding: 2px 8px;
  border-radius: 50px;
  border: solid 1px #b5d0c9;
  background-color: #e7faf5;
}
.tableRectangle {
  height: 349px;
  background-color: #fff;
}

.plannedDemand {
  width: 100%;
  height: 38px;
  // flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // margin: 6px 201px 0 21px;
  padding: 9px 0 9px 20px;
  border-radius: 4px;
  background-color: #a5a6f6;
  cursor: pointer;
}

.plannedDemand:hover {
  background-color: #8183dd;
  // border-left: 6px solid rgb(71, 84, 138);
}

.PlannedStateSelected {
  background-color: #8183dd;
  border-left: 6px solid rgb(71, 84, 138);
}

.PlannedStateAndQuantity {
  width: auto;
  height: 20px;
  font-family: Inter;
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-top: 0.75em;
}

.PlannedTag {
  height: 18px;
  font-family: Inter;
  margin-top: 13px;
  width: 130px;
  padding: 0px 10px;
  border-radius: 1rem;
  border: solid 1px #fdca63;
  background-color: #fff2d7;
}

.work-order-tag {
  height: 20px;
  margin-top: 13px;
  width: 130px;
  padding: 2px 8px;
  border-radius: 50px;
  font-size: 12px;
  border: solid 1px #fff;
  background-color: #fff;
}

.StatewiseWOs {
  margin: 20px 80px 11px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.statusText {
  margin: 10px 10px 11px 13px;
  font-family: SFProDisplay;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.plannedSupply {
  width: auto;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 8px;
  margin-right: 14px;
  padding: 3px 10px;
  border-radius: 4px;
  background-color: #86b4ce;
}

.supplyTagText {
  width: auto;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  text-align: left;
  color: #fff;
}
.supplyTagText .textSection {
  color: #fff;
}

.itemwiseAccordionDemandTableSection {
  width: 97%;
  height: 400px;
  overflow: auto;
}

.itemwiseAccordionSupplyTableSection {
  height: 400px;
  overflow: auto;
  width: 95%;
  margin-top: 15px;
}
.mapping_accordion_title_target {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #344054;
}
.mapping_accordion_title_target_fulfilment {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #344054;
}

.mapping_accordion_item_wise_title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #344054;
}
.subheading_planning_itemwise {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475467;
}
