.verticalSeperator {
  color: rgba(165, 166, 246, 0.41);
}

.dataSpacing {
  padding-left: 1em;
  padding-right: 0.5em;
}

.tableHeader {
  height: 43px;
  align-items: center;
  margin: 0 0 46px 0px;
  padding: 0 20px;
  background-color: rgba(165, 166, 246, 0.41);
}
.tableData {
  height: 63px;
  align-items: center;
  margin: 40px 0px 5px 0;
  padding: 0 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  cursor: pointer;
}
.sortImage {
  height: 20px;
  width: 20px;
  margin-left: 2em;
  margin-right: 0.5em;
}
.workAreaTable {
  overflow: scroll;
}

// ####################### Modal Styling ################################

.modalHeader {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-left: -10px;
}

.freeWorkOrderQuantityModal {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: #000;
}

.freeWorkOrderQuantityModalBodyHeader {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  margin-top: -18px;
}

.headerBrandNameLine {
  color: #979797;
  margin-left: 3px;
  margin-right: 3px;
}

.woCreationStyling {
  color: #979797;
  margin-bottom: 0px;
}

.vendorName {
  margin: 15px -10px;
}

.woDetailsRow {
  background-color: rgba(250, 250, 250, 0.6);
  border: 1px solid rgba(223, 222, 222, 0.5);
}

.cardFooter {
  border-top: 0px;
  margin-top: 10px;
}

.cancelButton {
  margin-right: 8px;
  width: auto;
  border: 1px solid #004acc;
  color: #004acc;
}
.submitBottom {
  width: '100%';
  text-align: 'centre';
  position: 'fixed';
  bottom: 0;
  font-size: 'large';
  font-family: 'font-bold';
}
.buttonDivStyle {
  display: flex;
  justify-content: flex-end;
}

//################################ DraftWO View/Edit ##############################################
.workOrderId {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 600;
  color: #252733;
}

.workOrderId .draftWOLine {
  font-weight: normal;
  color: #979797;
}

.workOrderId .itemAndState {
  font-weight: normal;
}

.plannedViewEditSecondHeader {
  background-color: #e4ebf8;
  margin-left: 1px;
  margin-right: 1px;
  height: 75px;
  margin-top: 15px;
}

.plannedViewEditSecondHeaderText {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0px;
}

.plannedViewEditSecondHeaderButtonSection {
  display: flex;
  justify-content: flex-end;
}

.brandwisePlannedEditViewHeaderCardHeader {
  font-size: 14px;
  color: #80807f;
  margin-bottom: 5px;
}

.brandwisePlannedEditViewHeaderCardValue {
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
}

.brandwisePlannedEditViewHeaderCardHorizontalLine {
  width: 2px;
  height: 50px;
  flex-grow: 0;
  margin-left: 15px;
  background-color: rgba(223, 222, 222, 0.5);
}

.plannedViewEditHeaderSection {
  display: flex;
  justify-content: space-between;
}

.brandwisePlannedEditViewHeaderCardTwoHeaderHorizontalLine {
  width: 1px;
  height: 55px;
  flex-grow: 0;
  margin: 1px 7.3px 2px 29.6px;
  background-color: rgba(165, 166, 246, 0.2);
}

.brandwisePlannedEditViewBodyDeliveriesText {
  font-size: 16px;
  font-weight: 600;
}

.brandwisePlannedEditViewBodyFilterHeader {
  font-size: 14px;
}

.brandwisePlannedEditViewBodyButtonStyle {
  color: #005dff;
  border: 1px solid #005dff;
  padding: 10px;
  font-family: 5px;
}

.brandwisePlannedEditViewHeaderFreeWOQuantityButtonStyleEnabled {
  color: #005dff;
  border: 1px solid #005dff;
  font-size: 16px;
  background-color: #fff;
  padding: 10px;
}

.brandwisePlannedEditViewHeaderFreeWOQuantityButtonStyleDisabled {
  color: #808080;
  border: 1px solid #808080;
  font-size: 16px;
  background-color: #fff;
  padding: 10px;
}

.brandwisePlannedEditViewBodyButtonDownloadIconStyle {
  width: 17px;
  margin: 3px;
  cursor: 'pointer';
}

.freeWorkOrderQuantityModalBodyFormSection {
  width: 737px;
  height: 136px;
  margin: 36px 2px 30px 0;
  padding: 17px 147px 14.5px 20px;
  border: solid 1px rgba(223, 222, 222, 0.5);
  background-color: #fafaff;
}

.freeWorkOrderQuantityModalBodyWODetailsText {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.replanningChangeAllocationHeaderText {
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 500;
  color: #000;
}

.replanningChangeAllocationSecondHeaderText {
  font-size: 14px;
  color: #80807f;
}

.replanningChangeAllocationSecondHeaderValue {
  font-size: 16px;
  color: #000;
}

.replanningChangeAllocationThirdHeaderSection {
  height: 100px;
}

.freeWOQuantityImage {
  width: 20px;
  margin-right: 3px;
}

.woAllocationCardSection {
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tableNoDataText {
  font-size: 14px;
  font-weight: 600;
}

.woAllocationTableSection {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.woAllocationDeliveriesTableSection {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.woChangeBrandAllocationTableSection {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.noDataSelectedFinancialYear {
  margin-top: 130px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.planning-form-row {
  margin: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  word-wrap: normal;
  row-gap: 0.75rem;
}

.planning-form-row-small {
  flex-grow: 0.2;
  flex-shrink: 0;
  flex-basis: 15%;
}

.planning-form-row-med {
  flex-grow: 1.5;
  flex-shrink: 1;
  flex-basis: 15%;
}

.planning-form-row-large {
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 15%;
}

.existing-po-input {
  display: flex;
  flex-direction: row;
  min-width: 8.25rem;
  padding: 0.75rem;
}

.cancel-onboard-modal {
  .modal-content {
    backdrop-filter: brightness(200%) !important;
    box-shadow: -2px 4px 28px 0px #0000001a;
    background-color: #fff;
    z-index: 99999;

    border-radius: 0.5rem;
    box-shadow: -2px 4px 28px 0px #0000001a;
    h6 {
      margin-top: 1.5rem !important;
      margin-bottom: 0.75rem !important;
    }
  }
}

.cancel-onboard-modal-footer {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: end;
  align-items: center;
  gap: 0.75rem;

  .cancel-onboard-primary-button {
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid #d92d20;
    background: #d92d20;
    height: 2.2rem;
    padding: 10px;
    border-radius: 0.4rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
  }
  .cancel-onboard-secondary-button {
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid #d0d5dd;
    background: #fff;
    height: 2.2rem;
    padding: 10px;
    border-radius: 0.4rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #344054;
  }
}

.table .planning-thead-light .planning-thead-main th {
  background-color: #f9fafb !important;
  font-weight: bold;
  font-family: Inter;
}

.table-container {
  position: relative;
  overflow-x: auto;
  height: 500px;
}

.table-container-overflow {
  overflow-x: auto;
  position: relative;
}

.sticky-table {
  border: 1px solid #eaecf0;
  width: 155%;

  thead th {
    border-bottom: 0;
  }
}

.sticky {
  position: sticky !important;
  background: white;
  z-index: 10;
}

th.sticky,
td.sticky,
td .text-right {
  width: 100px;
}

th:first-child.sticky,
td:first-child.sticky {
  left: 0;
  width: 50px;
}

th:nth-child(2).sticky,
td:nth-child(2).sticky {
  left: 50px;
  border-right: 2px solid #eaecf0;
  width: 200px;
}

th:last-child.sticky,
td:last-child.sticky {
  right: 0;
  border-left: 2px solid #eaecf0;
  width: 150px;
}

td:last-child.sticky {
  z-index: 0;
}
.vendorName_planning_recycler {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #667085;
}
.vendorName_planning_recycler_bold {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #344054;
}
.wo_creation_planning_modal_title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #667085;
}
.wo_creation_planning_modal_details {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #101828;
}
.wo_creation_planning_items_label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #344054;
}

.planning_table_bg_inherit {
  background: inherit !important;
}
