

.login-bg-image{
    height:100vh;
}
.login-form{
  margin-top:50%;
  transform: translateY(-50%);
}
.form-width{
  max-width: 350px;
}
  @media only screen and (max-width: 767px)  {
    .form-width{
       position:static;
       max-width: 100%;
       transform:translate(0);}
  }