.login-bg-image {
  height: 100vh;
}
.login-form {
  margin-top: 50%;
  transform: translateY(-50%);
}
.form-width {
  max-width: 350px;
}
.password-field {
  display: flex;
  justify-content: flex-end;
}

.icon-style {
  cursor: pointer;
  margin-top: 13px;
  font-size: 15px;
  margin-right: 5px;
}

.email-field:disabled {
  background-color: #e9ecef !important;
}

@media only screen and (max-width: 767px) {
  .form-width {
    position: static;
    max-width: 100%;
    transform: translate(0);
  }
}
