@import "../../../../scss/variables";

.documenthistory-comment-file{
  .doc-file{
    background: $light;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    opacity: 1;
  }
  .challan-doc{
    width: 27px;

    border: 0.5px solid #ECECEC;
  }
  .challen-jpg{
    font-size: 0.9286rem;
    color: #000000;

    .file-size{
      font-size:  0.7857rem;
      color: #B6B6B6;
    }
  }
}