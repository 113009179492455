
.scrollToTop {
  position: fixed;
  text-align: center;
  width: 100%;
  left: 57%;
  bottom: 20px;
  //visibility: hidden;
  display: none;
  //transition: 0.5s ease-in-out visibility;
  transform: translate(-50%, -57%);
  button{
    border-radius: 50px;
    padding: 5px 18px;
    color: white;
    border: 0;
    transition: 0.2s ease-in-out all;
    opacity: 0.7;
    &:hover{
      opacity: 1;
      color: white;
    }
  }
}

//
//
//// horizontal scrolling bar customise css
//::-webkit-scrollbar {
//  height: 6px;
//  width: 6px;
//  border: 1px solid #d5d5d5;
//  border-radius: 50px;
//}
//::-webkit-scrollbar {
//  width: 5px;
//  border: 1px solid #d5d5d5;
//  border-radius: 50px;
//}
//
//::-webkit-scrollbar-track {
//  border-radius: 0;
//  background: #eeeeee;
//}
//
//::-webkit-scrollbar-thumb {
// background: #b0b0b0;
//  border-radius: 50px;
//}
//
//::-webkit-scrollbar-track { 
//  background: transparent; 
//  border: 0;
//} 
//::-webkit-scrollbar{
//  border: 0;
//}
//
//// horizontal end scrolling bar customise css
//
//// scroll on hover
//.scrollbox {
//  overflow: auto;
//  visibility: hidden;
//  }
//
//.scrollbox-content,
//.scrollbox:hover,
//.scrollbox:focus {
//  visibility: visible;
//}
//
//// end scroll on hover
