.bulk {
  &-main {
    padding: 0.75em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }

  &-btn {
    display: flex;
    align-items: center;
    padding: 0.25em 1em;
    column-gap: 0.5em;
    font-size: 1rem;
  }

  &-modal {
    border-radius: 1em;

    &--body {
      height: 45vh;
      border-radius: 0.75em;
    }
  }
}

.upload_label {
  padding: 0.75em 1.5em;
  border-radius: 0.5em;
  background-color: #265cf6;
  color: white;
  width: max-content;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5em;
  cursor: pointer;
}

#bulk-upload-input {
  display: none;
}

.uploadDocCardStyle {
  padding: 0.75em 1em;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mapBatchWorkOrderHead {
  color: var(--Gray-800, #1d2939);
  font-family: 'font-bold', system-ui;
  font-size: 1.28571rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.28571rem;
}

.headerCardPadding {
  padding: 16px 24px;
}

.bodyCardPadding {
  padding: 24px !important;
}

.flex-end {
  justify-content: flex-end;
}
.flex-start {
  justify-content: flex-start;
}

.bulk_filters {
  display: flex;
  justify-content: flex-start;
  align-items: last baseline;
  column-gap: 1rem;
  font-family: Inter !important;
}

.bulk-delivery.nav-tabs .nav-item.nav-link.active {
  padding: 8px 15px !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2.5px solid #005dff !important;
  color: #000 !important;
  background-color: transparent !important;
  margin: 0 !important;
  margin-bottom: -2px !important;
  font-size: 16px !important;
  font-family: Inter;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.bulk-delivery.nav-tabs .nav-item.nav-link {
  padding: 8px 15px !important;
  border: 0 !important;
  margin: 0 !important;
  font-size: 16px !important;
  font-family: Inter;
  font-weight: 500 !important;
  color: #667085;
  opacity: 1 !important;
}

.bulk-delivery {
  border-bottom: 2px solid #e8e8e8 !important;
}

.batch_id--infoCard {
  background-color: #f5fbff;
  display: grid;
  column-gap: 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  .infoSection {
    row-gap: 0.25rem;
    &:not(:last-child) {
      border-right: 1.5px solid #d0d5dd;
    }
  }
}

.stepper {
  width: 100%;
  height: 60%;
  position: relative;

  &-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-image {
    margin-bottom: 0.75rem;
  }

  &-connector {
    &-1,
    &-2,
    &-3 {
      position: absolute;
      width: 32%;
      height: 2px;
      background-color: #d0d5dd;
      top: 8.5vh;

      &[data-active='true'] {
        background-color: #1570ef;
      }
    }

    &-1 {
      left: 20%;
    }

    &-2 {
      right: 15%;
    }
  }
}

// * TRANSACTION SCREENS * //
.transaction {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  &-body {
    padding: 1rem;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 0.65fr 1fr;
  }

  &-field {
    border: 1px solid #f1f1f1;
    border-radius: 5px;

    h6 {
      padding: 0.75rem;
      background-color: #f5f5f5;
      text-transform: uppercase;
    }
  }

  &-form {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.5rem 0 0.5rem 0;
    }
  }

  &-grid {
    &__card {
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
    }
  }

  &-preview {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 14rem auto;
    height: 79vh;
    overflow-y: scroll;

    & > div {
      background-color: #f5f5f5;
      height: inherit;
    }

    &__doclist {
      display: flex;
      flex-direction: column;

      .docSet {
        display: grid;
      }
    }

    .carousel {
      height: 100%;
      display: flex;
      row-gap: 10px;
      justify-content: space-between;
      flex-direction: column;

      &-header {
        flex: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__btn {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &-body {
        flex: 1;
        height: 62vh;

        &__frame {
          width: 100%;
          height: 100%;
        }

        &__image {
          height: 100%;

          img {
            width: 100%;
            height: inherit;
            display: block;
            margin-inline: auto;
            object-fit: contain;
          }
        }
      }

      &-footer {
        flex: 0;
        width: 100%;
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
      }

      &-indicator {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50% !important;
        background-color: #8190a7;

        &[data-change='true'] {
          background-color: #6ea3ff;
          outline: 1.5px solid #6ea3ff;
          outline-offset: 2px;
        }
      }
    }
  }
}

.grid-view-filter {
  display: grid;
  min-width: 25rem;
  column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  .react-select {
    .select__control {
      min-height: 33px;
      height: 33px;
      margin-bottom: 1rem;
    }
  }
}

.gap-2 {
  gap: 0.5rem !important;
}

.sticky {
  position: sticky !important;
  background: #ffffff;
  z-index: 10;
}

.table-responsive {
  border-bottom: 1px solid #d2d2d2;
  min-height: 300px;
}

.bulk-delivery-table {
  th:first-child.sticky,
  td:first-child.sticky {
    left: 0;
    width: 125px !important;
    min-width: 125px !important;
    box-shadow: 1px 0px 2px 0.5px #d9d9d9;
  }

  td,
  th {
    text-align: left;
    border: 0px inset;
    // border-bottom: 1px inset #d2d2d2;
    border-bottom: 1px inset #d2d2d2;
    font-family: Inter !important;
  }
  tbody {
    tr {
      td:first-child(1) {
        border-left: 1px solid #d2d2d2 !important;
      }
    }
  }
  thead {
    tr:first-child {
      th {
        background-color: #f9fafb !important;
      }
    }
    tr:nth-child(2) {
      th {
        background-color: #f5f5f5 !important;
      }
    }
  }

  th:last-child.sticky,
  td:last-child.sticky {
    right: 0px;
    width: 130px;
    min-width: 130px;
    max-width: 130px;
    box-shadow: -1px 0px 2px 0.5px #d9d9d9;
  }

  th.is-tooltip-column,
  td.is-tooltip-column {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
  }

  th:nth-last-child(2).sticky,
  td:nth-last-child(2).sticky {
    right: 100px;
    border-left: 2px solid #eaecf0;
    width: 75px;
    min-width: 75px;
    max-width: 75px;
  }

  th:nth-last-child(3).sticky,
  td:nth-last-child(3).sticky {
    right: 175px;
    border-left: 2px solid #eaecf0;
  }
}

.sticky-large {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}

.bulk-main {
  th,
  td {
    min-width: 175px !important;
  }
  td.is-tooltip-column,
  th.is-tooltip-column {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
  }
  th:first-child.sticky,
  td:first-child.sticky {
    left: 0;
    width: 75px !important;
    min-width: 75px !important;
    max-width: 75px !important;
  }
  th:first-child.sticky.sticky-large,
  td:first-child.sticky.sticky-large {
    left: 0;
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
  }
  th:nth-child(2).sticky,
  td:nth-child(2).sticky {
    left: 75px !important;
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
    box-shadow: 1px 0px 2px 0.5px #d9d9d9;
  }
  th:last-child.sticky,
  td:last-child.sticky {
    right: 0px;
    border-left: 2px solid #eaecf0;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    box-shadow: -1px 0px 2px 0.5px #d9d9d9;
  }

  th:nth-last-child(2).sticky,
  td:nth-last-child(2).sticky {
    right: 100px;
    width: 180px !important;
    min-width: 180px !important;
    max-width: 180px !important;
  }
  .sticky-large {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
  }
  thead {
    tr:nth-child(2) {
      .input-group .form-control {
        height: 26px;
        padding: 3px 5px 3px 5px;
        background: #ffffff !important;
        border: 0;
        border-radius: 6px;
      }

      .react-select .select__control {
        height: 26px !important;
        border: 0;
        min-height: 26px !important;
        max-height: 26px !important;
        background: #ffffff;
      }

      .react-select .select__control .select__placeholder,
      .react-select .select__control .select__single-value {
        top: 44%;
        font-size: 12px;
      }

      .react-select .select__control .select__indicator {
        padding-top: 2px;
      }

      .react-select .select__control .select__indicator-separator {
        width: 0 !important;
      }

      .react-select .select__menu {
        z-index: 999 !important;
        width: auto !important;
      }
    }
  }
}

.column-filter-input {
  height: 26px;
  padding: 3px 5px 3px 5px;
  background: #ffffff !important;
  border: 0;
  border-radius: 6px;
}

.column-filter-input-icon {
  height: 26px;
  border: 0;
  background: #ffffff !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
