// Styles
// import Inter
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Gravitas+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lugrasimo&family=Rubik+Dirt&family=Rye&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
.App-alert {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 60000;
}

.App-alert > * + * {
  margin-top: 1rem;
}
