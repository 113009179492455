/**
 * Style for all modals and popup should be written
 * here
 */

@media only screen and (max-width: 767px) {
  .modal-content {
    width: 100% !important;
  }
}
.small-modal {
  .modal-title {
    width: 100%;
  }
}
.close,
.close:focus,
.close:hover {
  box-shadow: none;
  outline: none;
}
.fixed-height {
  // .modal-dialog{
  //       height: 450px;
  // }
  form {
    //height: 400px;
    overflow: auto;
    overflow-x: hidden;
  }
  .modal-footer {
    border-top: none;
  }
  // .card-body {
  //     min-height: calc(100vh - 450px) !important;
  // }
  .modal-body {
    padding-bottom: 0;
  }
}

.modal-h {
  padding: 2rem;
  background: $light;
  .card-header {
    background: $light;
  }
}
.success-modal {
  height: 500px;
  p {
    font-size: 1.714rem;
    line-height: 1.2;
  }
  .or {
    font-weight: 600;
    color: #505050;
  }
  .another {
    font-size: 1.286rem;
    font-weight: 600;
    color: $primary;
    cursor: pointer;
  }
  .another:hover {
    color: $primary;
  }
}
.modal-full-height {
  .modal-dialog {
    height: 70vh;
  }
}

.modal-custom {
  .modal-content {
    height: 82vh;
  }
}
