@import 'src/scss/variables';
.btn-icons {
  .download-icon {
    background-image: url('../../assets/img/upload.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .btn-outline-secondary {
    background-color: $page-bg-color;
    border: 0;
  }
  .btn-outline-secondary:hover {
    background-color: $page-bg-color;
    border: 0;
    color: $secondary;
  }
}

.datepicker-wrapper {
  .datepicker-field {
    background-color: $page-bg-color;
    color: $dark;
  }
}
.modal-small {
  p {
    font-size: 1.286rem;
    font-family: $font-family-base;
  }
  h5 {
    color: $dark;
    font-size: 1.714rem;
    font-family: $font-family-base;
  }
  .modal-btn {
    padding: 0 35px 0 35px;
  }
  .close {
    font-size: 34px;
    font-weight: 300;
  }
  .anchor-tag {
    font-size: 1.286rem;
    color: $primary;
    font-family: $font-family-base;
  }

  @media (min-width: 576px) {
    .modal-sm {
      max-width: 350px;
    }
  }
}

.scrollable-columns {
  overflow: auto;
}
.heading-bar {
  height: 52px;
}
.targetText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 20px;
  font-weight: bold;
}
