@import 'src/scss/variables';
.ExecutionTargetList {
  overflow: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
  .container-fluid {
    padding: 0;
    & > div:first-child {
      display: none;
    }
    & > div:nth-child(2) {
      margin: 0;
      padding: 0;
    }
  }
  .table1 {
    width: 100%;

    td {
      &:first-child {
        padding: 0.55rem;
        font-size: 13px;
        min-width: 160px !important;
        max-width: 160px !important;
        border: 1px solid #d2d2d2;
        border-top: none;
      }
      padding: 0.55rem;
      font-size: 13px;
      min-width: 106px !important;
      max-width: 106px !important;
      border: 1px solid #d2d2d2;
      border-top: none;
    }
  }
  .brandtable {
    width: 100%;
    td {
      &:first-child {
        padding: 0.55rem;
        font-size: 13px;
        min-width: 198px !important;
        max-width: 198px !important;
        border: 1px solid #d2d2d2;
        border-top: none;
      }
      padding: 0.55rem;
      font-size: 13px;
      min-width: 132px !important;
      max-width: 132px !important;
      border: 1px solid #d2d2d2;
      border-top: none;
    }
  }
  table {
    th,
    td {
      padding: 0.55rem;
      font-size: 13px;
      min-width: 106px !important;
      max-width: 106px !important;
      cursor: pointer;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s;
      transition-timing-function: ease-in-out;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
    }

    thead {
      th {
        background-color: #f5f5f5;
        border-color: #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
        font-weight: normal;
        overflow: hidden;
        white-space: normal;
        vertical-align: middle !important;

        &:first-child {
          min-width: 160px !important;
          max-width: 160px !important;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .svg-inline--fa.fa-fw.fa-sort {
          color: #bbbbbb;
        }
      }
    }

    td {
      text-align: right;
      &:first-child {
        text-align: left;
        .svg-inline--fa.fa-fw.fa-angle-down + .iconPadding {
          //color: $primary;
          font-family: font-medium;
        }
      }
    }
    .header-proCol.client-side,
    .header-brandCol {
      background-color: #dcf7ff;
    }

    .proCol.client-side,
    .brandCol {
      background-color: #dcf7ff73;
      text-align: right;
      &:hover {
        background-color: #e4f8ff;
      }
    }

    .header-proCol.vendor-side,
    .header-vendor-side {
      background-color: #dee2ff;
    }
    //vendor column
    .proCol.vendor-side,
    .vendor-side {
      background-color: #dfe3ff69;
      // text-align: left;
      &:hover {
        background-color: #dee1fa;
      }
    }

    .header-self {
      background-color: #fff2cc;
    }

    .self {
      background-color: #fff2cc52;
      text-align: right;
      &:hover {
        background-color: #fff8df;
      }
    }

    .itemCol {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    //class for expanded coloring
    tr.shown.child-row td {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    tr.child-row td {
      //background: #F9F9F9!important;
      border-top: 0;
    }

    //full row BG
    tr.shown.child-row:nth-child(even) {
      background-color: #f9f9f9;
      td {
        //background: #ffffff;
      }
    }
    //
    //tr.shown.child-row:nth-child(odd) td{
    //    //background: #F9F9F9;
    //}

    tr.hidden.child-row:nth-child(even) {
      display: none;
    }

    tr.hidden.child-row ~ tr.shown.child-row:nth-child(odd) {
      //background-color: #f5f4f4;
    }

    tr.hidden.child-row ~ tr.shown.child-row:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
  //.no-colors{
  //    th{
  //        background-color: #f5f5f5!important;
  //    }
  //    td{
  //        background-color: #ffffff!important;
  //    }
  //}
  .extra-header {
    th {
      background-color: #f5f5f5 !important;
    }
    .demand {
      background-color: #dcf7ff !important;
    }
    .supply {
      background-color: #dee2ff !important;
    }
    .self {
      background-color: #fff2cc !important;
    }
  }
}
