@import "src/scss/variables";
.QuantityOverviewCard{
    padding: 7px;
    height: 100%;
    border: 0;
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.1);
    h6{
        font-weight: normal;
        font-family: font-semibold;
        letter-spacing: 1.8px;
        margin-bottom: 6px;
        text-transform: uppercase;
    }
    h3{
        font-weight: normal;
        font-family: font-semibold;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .top-badge{
        background: #EFF0F5 0% 0% no-repeat padding-box;
        border-radius: 14px;
        padding: 3px 8px;
        font-family: font-semibold;
        color: $primary;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .progress{
        height: 7px;
        border-radius: 5px;
        font-size: 0.7rem;
    }
    border-radius: 6px;
    padding: 15px;
}
