@import "../../../scss/variables";
.NotificationPermissionBar {
  top: 0;
  left: 0;
  width: 100%;
  max-height: $header-height;
  margin: 0;
  padding: 8px 10px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  background: #00b9ba;
  overflow: visible;
  z-index: 9999;
}
