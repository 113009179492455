.dashboard-data-card {
  height: 6rem;
  border: 0;
  min-width: 19rem;
  box-shadow: -2px 4px 28px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;

  > .d-flex {
    > .d-flex.flex-column {
      min-width: 19rem;
      > h6 {
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        font-weight: normal;
        font-family: font-semibold;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }
      > h3 {
        padding-left: 1rem;
        font-weight: normal;
        font-family: font-semibold;
      }
    }
  }
}

.dashboard-data-sub-card {
  cursor: pointer;
  height: 4.5rem;
  border: 0;
  background-color: rgba(243, 244, 246, 1);
  border-radius: 0.5rem;
  > .d-flex {
    height: 100%;
    > .d-flex.flex-column {
      min-width: 10rem !important;
      align-items: flex-start;
      padding-top: 0.75rem;
      padding-left: 1.5rem;
      justify-content: flex-start;
      gap: 0;
      > h6 {
        font-weight: 400;
        font-size: 0.875rem;
        font-family: font-semibold;
        letter-spacing: 1.8px;
        color: rgba(52, 64, 84, 1);
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
      > h3 {
        font-weight: 600;
        font-size: 1rem;
        font-family: font-semibold;
        color: rgba(52, 64, 84, 1);
      }
    }
  }
  margin-right: 1rem;
}
