@import '../../scss/variables';

.AuthContainer {
  @media only screen and (min-width: 1024px) {
    .login-card {
      height: auto;
      border-radius: 2.5px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;

      .card-header {
        padding: 2rem;
        background: #ffffff;
      }
      .card-body {
        padding: 2rem;
        background: #ffffff;
      }
    }
  }
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.5rem;
    line-height: 29px;
  }
  .card-body {
    min-height: auto !important;
  }

  .login-bg {
    height: 100vh;
    width: 100%;
  }

  .fs-16 {
    font-size: 1.143rem;
  }
  .radio-custom {
    .custom-control-label {
      font-family: font-regular;
      font-weight: normal;
    }
  }
  .nav-tabs {
    .nav-item.nav-link.active {
      background-color: #f6f8ff;
      border-radius: 5px;
      color: #2852ca;
    }
    .nav-item.nav-link {
      border-color: transparent;
      color: $dark;
      font-family: font-medium;
    }
    .nav-item.nav-link.active,
    .nav-item.nav-link {
      outline: 0;
      font-size: 1.143rem;
    }
  }
  .check-bg {
    background-color: #eff0f5;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 0;
    border: 0;
  }
  .form-label {
    font-size: 1rem;
  }
}
.AuthPage {
  .login-bg {
    position: fixed;
    height: 100vh;
    width: 50%;
  }
  .main-card {
    .card-body {
      min-height: auto;
    }
  }
  .AuthPageCard {
    @media only screen and (max-width: 1024px) {
      width: 300px;
    }
    width: 438px;
  }
}
