@import '../../../scss/variables';

.SingupContent{
  .custom-control{
    position: absolute;
    right: 0;
    top: 5px;
  }
  .check-bg{
    position: relative;
    width: 80px;
    height: 70px;
    border-radius: 3px;
    margin: 0 auto;
    background-color: #eff0f5;
    display: flex;
    justify-content: center;
    align-items: center;
    .custom-control-label{
      opacity: 0;
    }
    svg{
      path{opacity: 1;}
    }
  }
  .checked{
    .custom-control-label{
      opacity: 1;
    }
    svg{
      fill: $primary;
    }
  }
  .radio-custom{
    .custom-control-inline{
      margin-right: 0;
    }
  }
}

