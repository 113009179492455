@import 'variables';
//Button
button {
  //min-width: 100px;
}

//Button with icon
button {
}

//Icon button
button {
  &.btn-icon {
    min-width: auto;
  }

  &.back-btn {
    padding: 2px 8px 2px 8px;
    font-size: 1rem;
    margin-right: 10px;
  }
}

//button toolbar
.btn-toolbar {
  button {
    margin-left: 0.5em;
    min-width: 100px;
  }
}

//  upload and download icon button
.download-bg {
  background-image: url(../assets/img/download.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  padding: 15px;
}

.upload-bg {
  background-image: url(../assets/img/upload.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  padding: 15px;
}

// edit button
.edit-btn,
.edit-btn:hover,
.edit-btn:focus,
.edit-btn:active {
  font-size: 1rem !important;
  color: #000000 !important;
  background-color: #f5f5f5 !important;
  border: solid 0.5px #dfdede !important;
  border-radius: 4.5px;
  font-family: $font-family-base !important;
}

// Button with spinner
button {
  .spinner-border {
    vertical-align: revert;
  }

  .spinner-border-sm {
    border-width: 2px;
    vertical-align: baseline;
  }
}

.custom-btn-small-light {
  border-radius: 1.5px;
  border: solid 0.5px $btn-border-light;
  background-color: $btn-light;
}

.btn-primary {
  box-shadow: none !important;
  outline: none !important;
  font-family: 'font-semibold';
}

//toggle button group
.btn-group-toggle {
  height: $input-height;

  .btn {
    font-family: font-regular;
    background-color: #ededed;
    border-color: #dfdede;
    min-width: 65px;
    border-radius: 0;
    line-height: 2;
    font-size: 1rem;

    &:not(.disabled).active {
      background-color: $light;
    }
  }
}
.view-toggle {
  &.btn-group-toggle {
    height: inherit;
    .btn {
      font-family: font-regular;
      background-color: #e7edf9;
      border-color: $primary;
      min-width: 65px;
      border-radius: 4px;
      line-height: 2;
      padding: 2px 15px;
      font-size: 0.8rem;
      &.active {
        border: 1px solid $primary;
        background-color: $primary;
        color: white;
      }
    }
    .btn:nth-child(2) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
.delete-btn {
  padding: 0 0 0 0;
  background-color: $light !important;
  border: 1px solid $dark;
  border-radius: 0;
  color: $dark !important;
  font-weight: 600;
  height: 34px;
}

.delete-btn:hover {
  color: $dark !important;
}

.hide-btn {
  background-color: #d8d8d8;
  border-radius: 3px;
  ///* border: solid 0.5px #979797; */
  padding: 0px 6px 2px 6px;
  cursor: pointer;
  color: #7a7a7a !important;
  font-size: 1rem;
}

.nav-link-btton,
.nav-link-btton:hover,
.nav-link-btton:focus,
.nav-link-btton:active {
  background-color: $light !important;
  color: $primary !important;
  font-size: 1rem;
  font-family: $font-family-base;
  border: none;
  padding: 0 0 0 0;
}

.btn-align {
  display: flex;
  align-items: center;
}
.btn-lg {
  height: 41px;
  line-height: 24px;
  font-size: 1rem;
}
