.SustainabilityImpact {
  padding: 20px;

  .saved-section,
  .eliminated-section {
    position: relative;
    background-color: #f9f9f9;
    border-radius: 6px;
    padding: 42px 10px;
    line-height: 25px;
    .headline {
      position: absolute;
      top: -10px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      span {
        background-color: #ffffff;
        font-family: font-bold;
        color: #328e69;
        letter-spacing: 2px;
        text-transform: uppercase;
        border-radius: 50px;
        padding: 5px 10px;
      }
    }
    .col-4,
    .col-3,
    .col-6 {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      img {
        width: 29px;
      }
    }
    .title {
      font-family: font-bold;
      letter-spacing: 2px;
      color: #5b7583;
      margin-top: 5px;
      line-height: 17px;
      font-size: 10px;
    }
    .quantity {
      font-size: 16px;
      margin-top: 5px;
      font-family: font-bold;
    }
  }
  .saved-section {
  }
  .eliminated-section {
  }
}
