.tag {
  --clr-dark: #344054;
  --clr-done: #b54708;
  --clr-gray: #f9fafb;
  --clr-success: #067647;
  --clr-error: #b42318;
  --clr-pending: #175cd3;
  --clr-indicator: #f79009;

  --br-done: #fedf89;
  --br-gray: #eaecf0;
  --br-success: #abefc6;
  --br-error: #fecdca;
  --br-pending: #b2ddff;

  --bg-done: #fffaeb;
  --bg-success: #ecfdf3;
  --bg-error: #fef3f2;
  --bg-pending: #eff8ff;

  border-radius: 1em;
  width: max-content;
  padding: 0 0.5em;
  display: flex;
  align-items: center;
  column-gap: 0.25em;
  font-size: 0.9rem;
  font-weight: 500;

  &-indicator {
    width: 0.4em;
    height: 0.4em;
    border-radius: 50%;
    &-uploading {
      background-color: var(--clr-gray);
    }
    &-inprocess {
      background-color: var(--clr-success);
    }
    &-rejected {
      background-color: var(--clr-error);
    }
    &-processed {
      background-color: var(--clr-done);
    }
  }

  &-uploading {
    background-color: var(--bg-gray);
    border: 1px solid var(--br-gray);
    color: var(--clr-dark);
  }
  &-inprocess {
    background-color: var(--bg-success);
    border: 1px solid var(--br-success);
    color: var(--clr-success);
  }
  &-rejected {
    background-color: var(--bg-error);
    border: 1px solid var(--br-error);
    color: var(--clr-error);
  }
  &-processed {
    background-color: var(--bg-done);
    border: 1px solid var(--br-done);
    color: var(--clr-done);
  }

  &-pill {
    margin-left: 1rem;
    color: var(--clr-done);
    background-color: var(--bg-done);
    border: 1px solid var(--br-done);
    height: max-content;

    &--prefix {
      height: 0.35rem;
      width: 0.35rem;
      border-radius: 50%;
      background-color: var(--clr-indicator);
    }
  }

  &-status {
    height: max-content;

    &[data-status='Completed'] {
      color: var(--clr-success);
      background-color: var(--bg-success);
      border: 1px solid var(--br-success);
    }

    &[data-status='Incomplete'] {
      color: var(--clr-done);
      background-color: var(--bg-done);
      border: 1px solid var(--br-done);
    }
    &[data-status='Pending'] {
      color: var(--clr-error);
      background-color: var(--bg-error);
      border: 1px solid var(--br-error);
    }
    &[data-status='Partial'] {
      color: var(--clr-done);
      background-color: var(--bg-done);
      border: 1px solid var(--br-done);
    }
    &[data-status='Complete'] {
      color: var(--clr-success);
      background-color: var(--bg-success);
      border: 1px solid var(--br-success);
    }
  }

  &-compliance {
    height: max-content;
    border: 1px solid #d0d5dd;
    border-radius: 6px;

    &--Active {
      height: 0.4rem;
      width: 0.4rem;
      border-radius: 50%;
      background-color: var(--clr-success);
    }
    &--Inactive {
      height: 0.4rem;
      width: 0.4rem;
      border-radius: 50%;
      background-color: var(--clr-error);
    }
  }
}
