.RegisterGstinCard{
  min-height: 50vh;
  .addr-list-container{
    height: 41vh;
    overflow-y: scroll;
    padding: 0 5px 20px 0;
  }
  .card-header{
    h5{
      font-size: 1.5rem;
    }
    p{
      font-size: 1.5rem;
      line-height: 29px;
    }
  }
  .custom-control-label{
    font-size:1.143rem;
    font-family: font-semibold;
    padding-left: 7px;
  }
  span{
    font-size: 1rem ;
    color: #65677b;
    font-weight: 500;
  }
  .address-card{
    width: 100%;
    min-height: 75px;
    .custom-checkbox .custom-control-label::before {
      border: 1px solid #adaeb5;
    }
    &.selected{
      max-width: 100%;
      border-radius: 4px;
      box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.09);
      border: solid 0.5px #e4e4e4;
      background-color: #ffffff;
    }
  }
}
